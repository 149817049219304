import React from "react";
import styles from "./ButtonPage.module.scss";
import Button from "../../Components/Button";

const ButtonPage = (props) => {
	console.log(props);
	console.log(buttons[0][0]);
	return (
		<div>
			<h2>Buttons</h2>
			{buttons.map((button) => (
				<div className={styles.buttonGroup}>
					{button.map((b) => (
						<div className={styles.buttonDisplay}>
							<code>{b.display}</code>
							{b.component}
						</div>
					))}
				</div>
			))}
			<footer>
				<p>
					Created by <span>luca2849</span> - devChallenges.io
				</p>
			</footer>
		</div>
	);
};

const buttons = [
	[
		{
			display: "<Button />",
			component: <Button>Default</Button>,
		},
		{
			display: "&:hover, &:focus",
			component: <Button className="hovered">Default</Button>,
		},
	],
	[
		{
			display: "<Button variant='outline' />",
			component: <Button variant="outline">Default</Button>,
		},
		{
			display: "&:hover, &:focus",
			component: (
				<Button variant="outline" className="hovered">
					Default
				</Button>
			),
		},
	],
	[
		{
			display: "<Button variant='text' />",
			component: <Button variant="text">Default</Button>,
		},
		{
			display: "&:hover, &:focus",
			component: (
				<Button variant="text" className="hovered">
					Default
				</Button>
			),
		},
	],
	[
		{
			display: "<Button disableShadow />",
			component: <Button disableShadow>Default</Button>,
		},
	],
	[
		{
			display: "<Button disabled />",
			component: <Button disabled>Disabled</Button>,
		},
		{
			display: "<Button variant='text' disabled />",
			component: (
				<Button variant="text" disabled>
					Disabled
				</Button>
			),
		},
	],
	[
		{
			display: "<Button startIcon='cart-plus' />",
			component: <Button startIcon="cart-plus">Default</Button>,
		},
		{
			display: "<Button endIcon='cart-plus' />",
			component: <Button endIcon="cart-plus">Default</Button>,
		},
	],
	[
		{
			display: "<Button size='sm' />",
			component: <Button size="sm">Default</Button>,
		},
		{
			display: "<Button size='md' />",
			component: <Button size="md">Default</Button>,
		},
		{
			display: "<Button size='lg' />",
			component: <Button size="lg">Default</Button>,
		},
	],
	[
		{
			display: "<Button color='default' />",
			component: <Button color="default">Default</Button>,
		},
		{
			display: "<Button color='primary' />",
			component: <Button color="primary">Default</Button>,
		},
		{
			display: "<Button color='secondary' />",
			component: <Button color="secondary">Default</Button>,
		},
		{
			display: "<Button color='danger' />",
			component: <Button color="danger">Default</Button>,
		},
	],
	[
		{
			display: "&:hover, &:focus",
			component: (
				<Button className="hovered" color="default">
					Default
				</Button>
			),
		},
		{
			display: "\u00A0",
			component: (
				<Button className="hovered" color="primary">
					Default
				</Button>
			),
		},
		{
			display: "\u00A0",
			component: (
				<Button className="hovered" color="secondary">
					Default
				</Button>
			),
		},
		{
			display: "\u00A0",
			component: (
				<Button className="hovered" color="danger">
					Default
				</Button>
			),
		},
	],
];

export default ButtonPage;
