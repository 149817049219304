import "./App.css";

import SideNavigation from "./Components/Navigation/SideNavigation";
import ButtonPage from "./Pages/ButtonPage/ButtonPage";

function App() {
	return (
		<div class="content">
			<SideNavigation />
			<ButtonPage />
		</div>
	);
}

export default App;
