import React from "react";
import styles from "./SideNavigation.module.scss";

const SideNavigation = () => {
	return (
		<div className={styles.nav}>
			<div className={styles.brand}>
				<span>Dev</span>challenges.io
			</div>
			<nav>
				<ul>
					<li>Colors</li>
					<li>Typography</li>
					<li>Spaces</li>
					<li className={styles.active}>Buttons</li>
					<li>Inputs</li>
					<li>Grid</li>
				</ul>
			</nav>
		</div>
	);
};

export default SideNavigation;
